import React from "react";
import PropTypes from "prop-types";
import Slider from 'react-slick';
import { GatsbySeo } from "gatsby-plugin-next-seo";

const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <button
      className="focus:outline-none"
      onClick={onClick}
    >
      <img
        alt="left-arrow"
        src="/icons/symposium/white-arrow-left-symposium.svg"
        className={`z-40 w-8 h-8 lg:w-fit-content lg:h-fit-content left-0 my-auto`}
      />
    </button>
  )
}

const NextArrow = (props) => {
  const { onClick } = props;
  
  return (
    <button
      className="focus:outline-none"
      onClick={onClick}
    >
      <img
        alt="right-arrow"
        src="/icons/symposium/white-arrow-right-symposium.svg"
        className={`w-8 h-8 lg:w-fit-content lg:h-fit-content right-0 top-0`}
      />
    </button>
  )
}

const BluePrevArrow = (props) => {
  const { onClick } = props;
  
  return (
    <button
      className="focus:outline-none"
      onClick={onClick}
    >
      <img
        alt="left-arrow"
        src="/icons/symposium/blue-left-arrow-symposium.svg"
        className={`z-40 w-8 h-8 lg:w-fit-content lg:h-fit-content left-0 my-auto`}
      />
    </button>
  )
}

const BlueNextArrow = (props) => {
  const { onClick } = props;
  
  return (
    <button
      className="focus:outline-none"
      onClick={onClick}
    >
      <img
        alt="right-arrow"
        src="/icons/symposium/blue-right-arrow-symposium.svg"
        className={`w-8 h-8 lg:w-fit-content lg:h-fit-content right-0 top-0`}
      />
    </button>
  )
}

const settings = {
  rows: 1,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};

const cmeStatus = [
  "Anaesthesiologists  (3pts)",
  "Community Medicine (3pts)",
  "Emergency Medicine (3pts)",
  "O&G (Pending)",
  "Ophthalmologists (3pts)",
  "Orthopaedic Surgeons (3pts)",
  "Otorhinolaryngologists  (1.5pts)",
  "Pathologists  (3pts)",
  "Physicians  (3pts)",
  "Psychiatrists (3pts)",
  "Radiologists (3pts)",
  "Surgeons (3pts)",
  "Chiropractors Council (Pending)",
  "Nurse CNE (3pts)",
  "HKMA (3pts)"
]

const bottomSettings = {
  rows: 1,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <BlueNextArrow />,
  prevArrow: <BluePrevArrow />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        rows: 2,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};

const topCarousel = [
  { type: "Organizers", icon: ["/icons/symposium/chiron.svg", "/icons/symposium/health-care-federation.svg"] },
  { type: "Diamond Sponsors", icon: ["/icons/symposium/axa.svg","/icons/symposium/bamboo-sponsor.svg"] },
  { type: "Platinum Sponsors", icon: ["/icons/symposium/take2.svg"] }
];

const giftSponsors = [
  { type: "Gift Sponsors", icon: ["/icons/symposium/derml.svg"] },
  { type: "Media Partners", icon: ["/icons/symposium/bamboo-life.svg"] },
];

const bottomCarousel = [
  { title: "Dr. Annie Ng", role: "Specialist in\nGastroenterology\nand Hepatology", qualification: "MBBS\n(UNSW, Australia)\nMRCP (UK)\nFHKCP\nFHKAM\n(Medicine)", icon: "/images/symposium/speaker/annieng.png" },
  { title: "Dr. Eric Chan", role: "Specialist in\nRheumatology", qualification: "MBBS (HK)\nMRCP (UK)\nFHKCP\nFHKAM (Medicine)\nFRCP (Glasg)\nPDipID (HK)", icon: "/images/symposium/speaker/ericchan.png" },
  { title: "Dr. Kevin Chan", role: "Specialist in\nOtorhinolary-\nngology\n(ENT)", qualification: "MBBS (HK)\nMRCSEd\nFRCSEd (ORL)\nFHKCORL\nFHKAM\n(Otorhinolary-\nngology)", icon: "/images/symposium/speaker/kevinchan.png" },
  { title: "Dr. Orlando Chan", role: "Specialist in\nOphthalmology", qualification: "BSc BioSci (USA)\nMBBS (HKU)\nFRCS (Edin)\nFC Ophth HK\nFHKAM (Ophthalmology)", icon: "/images/symposium/speaker/orlandochan.png"},
  { title: "Dr. Wilson Lam", role: "Specialist in\nInfectious\nDisease", qualification: "MBChB (CUHK)\nMRCP (UK)\nFHKCP\nFHKAM(Medicine)\nFRCP (Edin)\nPDipID\n(HK)", icon: "/images/symposium/speaker/wilsonlam.png" }, 
  { title: "Dr. Dorothy Tam", role: "Specialist in\nPaediatrics", qualification: "MBChB (CUHK)\nMRCPCH (UK)\nFHKAM (Paed)\nFHKCPaed\nDip Med (CUHK)", icon: "/images/symposium/speaker/dorothytam.png" },
  { title: "Dr. Helen Tang", role: "Specialist in\nObstetrics\nand\nGynaecology", qualification: "MBBS (HK)\nMRCOG (UK)\nFHKAM (O and G)\nFHKCOG\nMRMed (UNSW)\nPGDipClinDerm\n(QMUL)", icon: "/images/symposium/speaker/helentang.png" },
  { title: "Dr. Hilda Wong", role: "Specialist in\nMedical Oncology", qualification: "MBBS (HK)\nMRCP (UK)\nFHKCP\nFHKAM\n(Medicine)", icon: "/images/symposium/speaker/hildawong.png" },
  { title: "Dr. Marcus Ying", role: "Specialist in\nGeneral Surgery", qualification: "MBBS (HK)\nFRCSEd (Gen)\nFCSHK\nFHKAM\n(Surgery)", icon: "/images/symposium/speaker/marcusying.png" },
  { title: "Dr. Jenny Lung", role: "Optometrist", qualification: "PhD\nBSc (Hons) Optom\nFAAO", icon: "/images/symposium/speaker/jennylung.png" },
  { title: "Ms. Amy Or", role: "Breast Care Nurse", icon: "/images/symposium/speaker/amyor.png" },
];

const leftStreamList = [
  { time: "13:30 - 14:00", title: "Dr. Annie Ng", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">GI - Update on Hepatitis B and C management</p> },
  { time: "14:00 - 14:30", title: "Dr. Helen Tang", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">O <strong className="font-normal font-en">&</strong> G - Update on HPV, cervical smear, colposcopy in the managment of cervical cancer</p> },
  { time: "14:30 - 15:00", title: "Dr. Kevin Chan", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">ENT - Role of FESS in the management of sinusitis</p> },
  { time: "15:00 - 15:30", title: "Dr. Wilson Lam", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">ID - Role of high-resolution anoscopy in prevention of HPV-related cancer</p> },
  { time: "15:30 - 15:50", title: "Break" },
  { time: "15:50 - 16:20", title: "Dr. Eric Chan", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">Rheumatology - Update on treatment of psoriatic arthritis</p> },
  { time: "16:20 - 16:50", title: "Dr. Dorothy Tam", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">Paediatrics - How to deal with picky eater (kids)?</p> },
];

const rightStreamList = [
  { time: "13:30 - 14:20", title: "Dr. Marcus Ying", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">Breast Cancer: Screening and Advanced Breast Biopsy Techniques</p> },
  { time: "14:30 - 15:00", title: "Ms. Amy Or", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">Nursing aspects - caring breast cancer patients in early post operation</p> },
  { time: "15:00 - 15:30", title: "Dr. Hilda Wong", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">Role of Precision Medicine in breast cancer management</p> },
  { time: "15:30 - 15:50", title: "Break" },
  { time: "15:50 - 16:20", title: "Dr. Orlando Chan", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">Myopia in Children and Adolescents</p> },
  { time: "16:20 - 16:50", title: "Dr. Jenny Lung", subtitle: <p className="text-sm-h4-card lg:text-lg-h4-article text-deep-blue-600">Optometrist view on Myopia control</p> },
];

const souvenirs = [
  { title: "Notebook Boxset", icon: "/images/symposium/notebook.png" },
  { title: "Business Card Holder", icon: "/images/symposium/card-holder.png" },
  { title: "Eco-Friendly Bag", icon: "/images/symposium/bag.png" },
  { title: "Cushion", icon: "/images/symposium/cloud.png" },
  { title: "Derml A - All Purpose Moisturizer", icon: "/images/symposium/cream.png" },
];

const SymposiumPage = () => {
  //const { shortFormLocale } = pageContext;

  return (
    <div className="h-screen w-full">
      <GatsbySeo
        title="Symposium | Chiron Medical"
        description="Symposium | Chiron Medical"
        openGraph={{
          type: 'website',
          url: "chiron.care/symposium",
          title: "Symposium | Chiron Medical",
          description: "Symposium | Chiron Medical",
          images: [
            {
              url: "/images/symposium/symposium-og.jpg",
              width: 2500,
              height: 1310,
              alt: "Symposium | Chiron Medical",
            },
          ],
        }}
      />
      <div className="flex flex-col items-center" style={{ fontFamily: "Playfair Display" }}>
        <div className={`relative h-sm-symposium-header lg:h-full w-full masked`}>
          <img
            alt="header-background"
            src="/images/symposium/symposium.png"
            className="w-full h-full object-cover"
          />
          <div className={`flex flex-col items-center px-sm-card absolute w-full text-center-embedded`}>
            <h1 className={`text-sm-h1 lg:text-lg-h1 lg:leading-14 leading-7 text-white text-center whitespace-pre-line mb-sm-card lg:mb-lg-card font-bold tracking-wider`}>
              CHIRON MEDICAL SYMPOSIUM
            </h1>
            <p className={`tracking-widest text-white text-center whitespace-pre-line text-sm-h5 lg:text-sm-h1 leading-6 lg:leading-11`}>
              Multi-Specialties Update
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-evenly w-full py-sm-content lg:py-lg-subtopic">
          <div className="h-full w-inline lg:hidden mx-auto">
            <img alt="middle-banner" src="/images/symposium/middle-date-banner.png" className="h-full w-full" />
          </div>
          <div className="h-full w-inline lg:w-1/3 bg-white mx-auto lg:mx-0 lg:my-auto">
            <div className="flex flex-col h-full justify-between" style={{ fontFamily: "Francois One" }}>
              <div className="w-full flex flex-col mt-sm-content lg:mt-0">
                <h1 className="text-deep-blue-600 text-sm-h4-card lg:text-lg-h4-article">Format</h1>
                <h2 className="text-deep-blue-600 whitespace-pre-line text-sm-h3-article lg:text-30px">
                  {`Online Platform`}
                </h2>
              </div>
              <div className="w-full flex flex-col mt-sm-subtopic lg:mt-lg-subtopic">
                <h1 className="text-deep-blue-600 text-sm-h4-card lg:text-lg-h4-article">Theme</h1>
                <h2 className="text-deep-blue-600 text-sm-h3-article lg:text-30px">Multi-Specialties Update</h2>
              </div>
              <div className="w-full flex flex-col mt-sm-subtopic lg:mt-lg-subtopic">
                <h1 className="text-deep-blue-600 text-sm-h4-card lg:text-lg-h4-article">Target Audiences</h1>
                <h2 className="text-deep-blue-600 whitespace-pre-line text-sm-h3-article lg:hidden">
                  {`Doctors (GPs/ Specialists),\nNurses and\nAllied Health Professionals`}
                </h2>
                <h2 className="text-deep-blue-600 whitespace-pre-line text-sm-h3-article lg:text-30px hidden lg:block">
                  {`Doctors (GPs/ Specialists),\nNurses and Allied Health Professionals`}
                </h2>
              </div>
            </div>
          </div>
          <div className="h-full w-1/3 hidden lg:block my-auto">
            <img alt="middle-banner" src="/images/symposium/middle-date-banner.png" className="h-full w-full" />
          </div>
        </div>
        <div className="relative w-full h-fit-content" style={{ fontFamily: "Playfair Display" }}>
          <img
            alt="stream-background"
            src="/icons/symposium/stream-background.svg"
            className="absolute object-cover top-0 w-full h-full -z-10"
          />
          {/* <img
            alt="index-health-info-carousel-background-mobile"
            src={innerContent.health_info.background.mobile}
            className="absolute top-0 w-full h-full lg:hidden -z-10"
          /> */}
          <div className="flex flex-col items-center lg:items-baseline lg:flex-row justify-evenly py-sm-content lg:py-lg-content">
            <div className="flex flex-col w-inline lg:w-1/3">
              <h1 className="text-deep-blue-600 text-sm-h2 lg:text-36px font-bold">Speciality Update Stream</h1>
              <div className="border-t border-solid border-deep-blue-600 w-full mt-sm-card mb-lg-card" />
              {
                leftStreamList.map((section) => (
                  <>
                    <div className="flex flex-row items-center lg:items-baseline">
                      <h2 className="text-sm-h2 text-deep-blue-600 w-3/10 lg:leading-12 font-bold">{section.time}</h2>
                      <div className="flex flex-col w-7/10 pl-sm-card lg:pl-sm-subtopic">
                        <h1 className="text-sm-h2 font-bold lg:text-30px leading-10 lg:leading-12" style={{ color: "#F0A44D" }}>{section.title}</h1>
                        { section.subtitle }
                      </div>
                    </div>
                    <div className="border-t border-solid border-deep-blue-600 w-full my-40px" />
                  </>
                ))
              }
            </div>
            <div className="flex flex-col w-inline lg:w-1/3 mt-sm-content lg:mt-0">
              <h1 className="text-deep-blue-600 text-sm-h2 lg:text-36px font-bold">Multi-Disciplinary Team Stream</h1>
              <div className="border-t border-solid border-deep-blue-600 w-full mt-sm-card mb-lg-card" />
              {
                rightStreamList.map((section) => (
                  <>
                    <div className="flex flex-row items-center lg:items-baseline">
                      <h2 className="text-sm-h2 text-deep-blue-600 w-3/10 lg:leading-14 font-bold">{section.time}</h2>
                      <div className="flex flex-col w-7/10 pl-sm-card lg:pl-sm-subtopic">
                        <h1 className="text-sm-h2 font-bold lg:text-30px leading-10 lg:leading-12" style={{ color: "#F0A44D" }}>{section.title}</h1>
                        { section.subtitle }
                      </div>
                    </div>
                    <div className="border-t border-solid border-deep-blue-600 w-full my-40px" />
                  </>
                ))
              }
            </div>
          </div>
        </div>
        <div className="w-full px-1 lg:px-sm-subtopic">
          <div className="flex flex-col items-center">
            <h1 className="text-deep-blue-600 text-sm-h2 lg:text-lg-h3-article text-center font-bold lg:font-normal mt-0 lg:mt-lg-subtopic">Speaker</h1>
            <div className="border-t border-solid border-deep-blue-600 w-32 lg:w-inline" />
          </div>
          <div className={`flex flex-col items-center justify-center h-full`}>
            <Slider {...bottomSettings} className="w-full flex flex-row items-center justify-center mb-sm-content lg:mb-lg-subtopic lg:mt-lg-subtopic">
              {
                bottomCarousel.map((e, index) => (
                  <div className="slick-slide-center" key={ index }>
                    <div className="relative h-sm-symposium-speaker w-sm-symposium-speaker mt-sm-subtopic lg:mt-0 lg:h-symposium-speaker lg:w-symposium-speaker">
                      <img alt="speaker-background" src="/images/symposium/speaker-background.png" className="w-full h-full object-cover -z-10" />
                      <div className="absolute top-vertical-speaker-details left-horizontal-speaker-details lg:top-desktop-vertical-speaker-details lg:left-desktop-horizontal-speaker-details flex flex-col z-20">
                        <h1 className="text-lg-h3-card font-bold" style={{ color: "#F0A44D" }}>{e.title}</h1>
                        <p className="text-sm-h4-card lg:text-lg-h4-card whitespace-pre-line text-deep-blue-500 my-sm-card">
                          {e.role}
                        </p>
                        {
                          index === 1 ? (
                            <h2 className="text-sm-p lg:text-lg-p text-deep-blue-600 whitespace-pre-line font-bold">
                              {`MBBS (HK)\nMRCOG (UK)\nFHKAM (O `}<strong className="font-normal font-en">&</strong>{` G)\nFHKCOG\nMRMed (UNSW)\nPGDipClinDerm\n(QMUL)`}
                            </h2>
                          ) : (
                            <h2 className="text-sm-p lg:text-lg-p text-deep-blue-600 whitespace-pre-line font-bold">
                              {e.qualification}
                            </h2>
                          )
                        }
                        
                      </div>
                      <img alt="speaker" src={e.icon} className="absolute bottom-0 right-0 object-cover w-auto z-10" style={{ height: "80%" }} />
                    </div>
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>
        <div className="flex flex-col w-full py-sm-content lg:py-lg-subtopic" style={{ backgroundColor: "#F0A44D" }}>
          <h1 className="text-white text-sm-h1 lg:text-lg-h3-article text-center font-bold lg:font-normal" style={{ fontSize: "48px" }}>CME Status</h1>
          <div className="flex flex-col lg:flex-row w-full py-sm-content lg:py-lg-subtopic">
            <div className="w-full justify-evenly mt-6 hidden lg:flex text-white">
              <ul className="space-y-8">
                {
                  cmeStatus.slice(0, 5).map((item, index) => (
                    <li className="text-sm-h1" key={item}>{`${index+1} ) ${item}`}</li>
                  ))
                }
              </ul>
              <ul className="space-y-8">
                {
                  cmeStatus.slice(5, 10).map((item, index) => (
                    <li className="text-sm-h1" key={item}>{`${index+6} ) ${item}`}</li>
                  ))
                }
              </ul>
              <ul className="space-y-8">
                {
                  cmeStatus.slice(10, cmeStatus.length).map((item,index) => (
                    <li className="text-sm-h1" key={item}>{`${index+11} ) ${item}`}</li>
                  ))
                }
              </ul>
            </div>
            <div className="mt-6 block lg:hidden">
              <ul className="space-y-4 text-center text-white">
                {
                  cmeStatus.map((item, index) => (
                    <li className="text-sm-h1" key={item}>{index+1} { item }</li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full bg-deep-blue-600 py-lg-subtopic">
          <div className="flex flex-col items-center">
            <h1 className="text-white text-sm-h1 lg:text-lg-h3-article text-center font-bold lg:font-normal">Souvenirs</h1>
          </div>
          <div className="hidden mt-10 flex-col lg:flex-row lg:flex">
            {
              souvenirs.map((souvenir, index) => (
                <div className={`flex flex-col flex-1 px-lg-subtopic`} key={souvenir.title}>
                  <img alt="souvenir" src={souvenir.icon} className={`w-full h-full object-scale-down ${index !== 0 ? "mt-lg-subtopic lg:mt-0" : ""}`} />
                </div>
              ))
            }
          </div>
          <div className="hidden flex-col lg:flex-row py-lg-subtopic lg:flex">
            {
              souvenirs.map((souvenir) => (
                <div className={`flex flex-col flex-1 px-lg-subtopic`} key={souvenir.title}>
                  <h1 className="text-white text-sm-h1 text-center mt-lg-card">{souvenir.title}</h1>
                </div>
              ))
            }
          </div>
          <div className="lg:hidden">
            <Slider {...settings} className="w-full flex flex-row items-center justify-center lg:mt-lg-subtopic">
              {
                souvenirs.map((souvenir, index) => (
                  <div className="flex flex-col w-full px-sm-subtopic pt-sm-subtopic" key={ index }>
                    <img alt="souvenir" src={souvenir.icon} className={`w-full h-full object-scale-down`} />
                    <h1 className="text-white text-sm-h2 text-center mt-lg-card">{souvenir.title}</h1>
                  </div>
                ))
              }
            </Slider>
          </div>
          <h2 className="text-white text-lg-h4-card w-inline lg:w-full lg:px-lg-subtopic mx-auto mt-40px">*Souvenirs are only available while stocks last</h2>
        </div>
        <div className="w-full px-1 lg:px-sm-subtopic h-fit-content mt-sm-subtopic lg:my-0 lg:h-symposium-header-carousel">
          <div className={`flex flex-col items-center justify-center h-full`}>
            <div className="w-full flex flex-col lg:flex-row justify-evenly items-center">
              {
                topCarousel.map((e, index) => (
                  <div className="flex flex-col lg:flex-row items-center w-full lg:w-fit-content my-sm-subtopic lg:my-0 lg:h-symposium-header-carousel px-1" key={ index }>
                    <div className="w-1/2 lg:w-fit-content">
                      <h1 className="text-sm-h3-article lg:text-sm-h2 text-center leading-8 lg:mr-sm-subtopic whitespace-no-wrap mb-sm-card lg:mb-0" style={{ color: "#B4B4B5" }}>{e.type}</h1>
                    </div>
                    <div className="w-1/2 flex flex-col lg:flex-row lg:w-fit-content">
                      {
                        e.icon.map((i, index) => (
                          <img alt={`sponsor-${index}`} src={i} className={`mx-auto ${i.includes("federation") ? "w-insurance-charging-card-image" : ""} ${index !== 0 ? "mt-sm-subtopic lg:mt-0 lg:ml-40px": ""}`} key={ index } />
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="w-full px-1 lg:px-sm-subtopic h-fit-content mb-sm-subtopic lg:my-0 lg:h-symposium-header-carousel">
          <div className={`flex flex-col items-center justify-center h-full`}>
            <div className="w-full flex flex-col lg:flex-row justify-evenly items-center">
              {
                giftSponsors.map((e, index) => (
                  <div className="flex flex-col lg:flex-row items-center w-full lg:w-fit-content my-sm-subtopic lg:my-0 lg:h-symposium-header-carousel px-1" key={ index }>
                    <div className="w-1/2 lg:w-fit-content">
                      <h1 className="text-sm-h3-article lg:text-sm-h2 text-center leading-8 lg:mr-sm-subtopic whitespace-no-wrap mb-sm-card lg:mb-0" style={{ color: "#B4B4B5" }}>{e.type}</h1>
                    </div>
                    <div className="w-1/2 flex flex-col lg:flex-row lg:w-fit-content">
                      {
                        e.icon.map((i, index) => (
                          <img alt={`gift-sponsor-${index}`} src={i} className={`mx-auto ${i.includes("federation") ? "w-insurance-charging-card-image" : ""} ${index !== 0 ? "mt-sm-subtopic lg:mt-0 lg:ml-40px": ""}`} key={ index } />
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/5">
            <img alt="contact-us" src="/images/symposium/contact-us.png" className="h-full w-full object-cover" />
          </div>
          <div className="w-full lg:w-3/5 h-full flex flex-col lg:px-lg-content py-lg-subtopic mx-auto lg:mx-0 my-auto text-center lg:text-left">
            <h1 className="text-sm-h3-article lg:text-36px font-bold" style={{ fontFamily: "Playfair Display" }}>
              Contact Us
            </h1>
            <div className="flex flex-col my-sm-subtopic xl:my-sm-content" style={{ fontFamily: "Playfair Display" }}>
              <h3 className="text-sm-h4-article lg:text-sm-h2 text-deep-blue-600">
                Ms. Angela Lai
              </h3>
              <h3 className="text-sm-h4-article lg:text-sm-h2 text-deep-blue-600 mt-sm-card">
                Phone: 2136 5430
              </h3>
              <h3 className="text-sm-h4-article lg:text-sm-h2 text-deep-blue-600 mt-sm-card">
                Email: angelalai@i-concept-event.com
              </h3>
            </div>
            {/* <div className={`h-10 lg:h-14 font-bold leading-loose cursor-pointer rounded-custom outline-none bg-deep-blue-600 w-fit-content mt-lg-card mx-auto lg:mx-0`} >
              <Link to="/symposium/registration">
                <div className="flex flex-row justify-center mx-sm-subtopic lg:mx-sm-subject h-full items-center">
                  <h2 className="text-sm-h3-card lg:text-lg-h4-article tracking-wider text-white leading-4" style={{ fontFamily: "Francois One" }}>
                    Register Now
                  </h2>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

BluePrevArrow.propTypes = {
  onClick: PropTypes.func,
};

BlueNextArrow.propTypes = {
  onClick: PropTypes.func,
};

export default SymposiumPage;

